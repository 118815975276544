import * as React from 'react';
import clsx from 'clsx';

import baseStyles from '../base.module.css';
import styles from './ProductListView.module.css';

interface Props {
  imageUrls: string[];
  logoUrl: string;
  supplierName: string;
  headerDescription: string;
  bannerTitle: string;
  bannerDescription: string;
}

export const KeyVisualView = ({
  imageUrls,
  logoUrl,
  supplierName,
  headerDescription,
  bannerTitle,
  bannerDescription,
}: Props) => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  const mainImageStyle = {
    transform: `translateX(-${activeIndex * 100}%)`,
  };

  const handlePrev = () =>
    setActiveIndex(activeIndex <= 0 ? imageUrls.length - 1 : activeIndex - 1);

  const handleNext = () =>
    setActiveIndex(activeIndex >= imageUrls.length - 1 ? 0 : activeIndex + 1);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      handleNext();
    }, 5 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [activeIndex]);

  if (imageUrls.length === 0) {
    return null;
  }

  return (
    <div className={styles['mainvisual']}>
      <div className={styles['mainvisual__inner']}>
        <header className={clsx(styles['header'], baseStyles['color-bg'])}>
          <a className={styles['header__logo']}>
            <img src={logoUrl} />
          </a>
          <div className={styles['header__desc']}>
            <p>{supplierName}</p>
            <h1>{headerDescription}</h1>
          </div>
          <svg
            className={clsx(styles['header__ic1'], baseStyles['color-fill'])}
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
          >
            <path d="M0,32V0H32A32,32,0,0,0,0,32Z" />
          </svg>
          <svg
            className={clsx(styles['header__ic2'], baseStyles['color-fill'])}
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
          >
            <path d="M0,32V0H32A32,32,0,0,0,0,32Z" />
          </svg>
        </header>
        <div className={styles['mainvisual__pic']}>
          <div className={styles['c-photo__main']}>
            <ul className={styles['c-photo__main__list']}>
              {imageUrls.map((url) => (
                <li
                  className={styles['c-photo__main__list__item']}
                  style={mainImageStyle}
                  key={url}
                >
                  <img src={url} alt="banner" />
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className={clsx(styles['mainvisual__desc'], baseStyles['color-bg'])}>
          <div className={styles['title']}>{bannerTitle}</div>
          <p>{bannerDescription}</p>
          <svg
            className={clsx(styles['mainvisual__desc__ic1'], baseStyles['color-fill'])}
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
          >
            <path d="M0,32V0H32A32,32,0,0,0,0,32Z" />
          </svg>
          <svg
            className={clsx(styles['mainvisual__desc__ic2'], baseStyles['color-fill'])}
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
          >
            <path d="M0,32V0H32A32,32,0,0,0,0,32Z" />
          </svg>
        </div>

        {imageUrls.length > 1 && (
          <>
            <a
              className={clsx(styles['pictures__all__photo__btn'], styles['prev'])}
              onClick={handlePrev}
            >
              <span className={baseStyles['color-sub--bg']}></span>
              <span className={baseStyles['color-bor--black']}></span>
            </a>
            <a
              className={clsx(styles['pictures__all__photo__btn'], styles['next'])}
              onClick={handleNext}
            >
              <span className={baseStyles['color-sub--bg']}></span>
              <span className={baseStyles['color-bor--black']}></span>
            </a>
          </>
        )}
      </div>
    </div>
  );
};
